<template>
  <ValidationObserver ref="objects" v-slot="{ invalid }">
    <v-form @submit.prevent="changePassword">
      <ValidationProvider name="password_old" rules="required|min:10|max:20">
        <v-text-field
          id="password_old"
          label="Old Password"
          name="password_old"
          v-model="password_old"
          type="password"
          autocomplete="new-password"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>

      <ValidationProvider name="password" rules="required|min:10|max:20">
        <v-text-field
          id="password"
          label="New Password"
          name="password"
          v-model="password"
          type="password"
          autocomplete="new-password"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <ValidationProvider name="Password confirmation" rules="required|confirmed:password">
        <v-text-field
          id="password_confirmation"
          label="Confirm Password"
          name="password_confirmation"
          v-model="password_confirmation"
          type="password"
          autocomplete="new-password"
          :error-messages="errors"
          slot-scope="{ errors }"
        />
      </ValidationProvider>
      <v-card-actions class="d-flex justify-end">
        <v-btn type="submit" :disabled="invalid" color="primary">Confirm</v-btn>
      </v-card-actions>
    </v-form>
  </ValidationObserver>
</template>

<script>
// import { getError } from "@/utils/helpers";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min, confirmed } from 'vee-validate/dist/rules';
// import { mapGetters } from 'vuex';
import AuthService from '@/services/AuthService';

extend('required', required);
extend('max', max);
extend('min', min);
extend('confirmed', confirmed);

export default {
  name: 'ChangePasswordForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      password_old: '',
      password: '',
      password_confirmation: ''
    };
  },
  methods: {
    async changePassword() {
      const payload = {
        password_old: this.password_old,
        password: this.password,
        password_confirmation: this.password_confirmation
      };
      this.$store.dispatch('ui/loading', true);
      try {
        const response = await AuthService.changePassword(payload);
        console.log(response);
        if (response && response.data?.status === 1) {
          this.$store.dispatch('ui/snackbarSuccess', 'Reset password complete.');
        } else {
          this.$store.dispatch('ui/snackbarError', response.data?.message ?? 'Error occured');
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('ui/snackbarError', 'Unable to reset password.');
      }
      this.$store.dispatch('ui/loading', false);
    }
  }
};
</script>
