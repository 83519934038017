<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">Change Password</h1>
    <ChangePasswordForm />
  </v-container>
</template>

<script>
import ChangePasswordForm from '@/components/ChangePasswordForm';

export default {
  name: 'ChangePasswordView',
  components: {
    ChangePasswordForm
  },
  data: () => ({
    tabs: null,
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      }
    ]
  })
};
</script>
